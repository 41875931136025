<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo_livera.png"> -->
    <HomeContent/>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App Livera"/> -->
  </div>
</template>

<script>
import HomeContent from './components/HomeContent.vue'

export default {
  name: 'App',
  components: {
    HomeContent
  }
}
</script>


<style>
@import "./assets/style.css";
</style>
