<template>
    <div class="main-menu">
        <div class="main-menu-bar">
            <img src="../assets/images/background_img.png" class="bg-color">
            <div class="main-container">
                <h2 class="text-dna">DIGITAL NATIVE AGENCY</h2>
                <div class="main-content">
                    <h2 class="text-content">Digitalize Your Business Through Innovation</h2>
                </div>
                <a href="#" class="btn learn-more"><span class="text-learn-more">Learn more</span></a>
            </div>
            <div class="menu-bar-container">
                <img class="logo-dna" src="../assets/logo/logo_dna.png" alt="Logo DNA">
                <div class="logo-menu-bar">
                    <img class="logo-frame" src="../assets/logo/logo_frame.png" alt="Frame">
                    <img class="logo logo-linkedin" src="../assets/logo/logo_linkedin.png" alt="LinkedIn">
                    <img class="logo logo-facebook" src="../assets/logo/logo_facebook.png" alt="Facebook">
                    <img class="logo logo-instagram" src="../assets/logo/logo_instagram.png" alt="Instagram">
                </div>
            </div>
        </div>
        <div class="mid-menu">
            <div class="mid-wrapper">
                <div class="mid content-years">
                    <p class="txt-number">2</p>
                    <p class="txt-content">Year of Experience</p>
                </div>
                <div class="mid content-projects">
                    <p class="txt-number">10</p>
                    <p class="txt-content">Successful Projects</p>
                </div>
                <div class="mid content-clients">
                    <p class="txt-number">6</p>
                    <p class="txt-content">Happy Clients</p>
                </div>
            </div>
            <div class="mid-service">
                <div class="service-wrapper">
                    <p class="txt-service"> Service That We Can Provide</p>
                </div>
                <img class="img-service" src="../assets/images/line.png" alt="Line">
                <div class="mid-icon">
                    <div class="mid-icon-content">
                        <div class="icon img-ui">
                            <img src="../assets/images/uiux_dev.png" alt="UIUX">
                        </div>
                        <p class="text txt-ui">UI/UX Design</p>
                        <div class="icon img-web">
                            <img src="../assets/images/website_dev.png" alt="Website">
                        </div>
                        <p class="text txt-web">Website Development</p>
                        <div class="icon img-mobile">
                            <img src="../assets/images/mobile_dev.png" alt="Mobile">
                        </div>
                        <p class="text txt-mobile">Mobile Apps Development</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="project-menu">
            <p class="txt-our-project">Our Project</p>
            <div class="line-project"></div>
            <div class="our-project-content">
                <div class="img-project-livera">
                    <img src="../assets/images/livera_box.png" alt="Livera">
                    <p class="txt-project">C-PRO</p>
                </div>
                <div class="img-project-kolita">
                    <img src="../assets/images/box_default.png" alt="Kolita">
                    <p class="txt-project">Atmajaya (KOLITA)</p>
                </div>
                <div class="img-project-wvi">
                    <img src="../assets/images/box_default.png" alt="Binbank">
                    <p class="txt-project">Binbank (WVI)</p>
                </div>
            </div>
            <div class="img-arrow">
                <img class="arrow-back" src="../assets/logo/icon_back.png" alt="Back">
                <img class="arrow-next" src="../assets/logo/icon_next.png" alt="Next">
            </div>
        </div>
        <div class=client-menu>
            <div class="title-client">
                <p>OUR HAPPY CLIENT</p>
            </div>
            <div class="line-client"></div>
            <div class="logo-client">
                <img class="logo-livera" src="../assets/images/logo_livera.png">
                <img class="logo-wvi" src="../assets/images/logo_wvi.png">
                <img class="logo-atmajaya" src="../assets/images/logo_atmajaya.png">
                <!-- <img class="logo-livera" src="../assets/images/logo_livera.png">
                <img class="logo-wvi" src="../assets/images/logo_wvi.png">
                <img class="logo-atmajaya" src="../assets/images/logo_atmajaya.png"> -->
            </div>
            <div class="img-arrow-two">
                <img src="../assets/logo/icon_back_02.png" class="arrow-back">
                <img src="../assets/logo/icon_next_02.png" class="arrow-next">
            </div>
        </div>
        <div class="menu-footer">
            <div class="">
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeContent'
}
</script>


<style scoped>

</style>